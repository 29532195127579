<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>

      <div v-if="event">
        <v-toolbar v-if="eventUtil.newLayout" elevation="0" color="grey lighten-5">
          <v-toolbar-title class="ml-3 py-2 subtitle">
            Event Details
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div>
            <v-btn v-if="!event.published" @click="showPreviewDialog=true" class="mr-4">
              <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
              Preview
            </v-btn>
            <v-btn v-if="eventUtil.hasRole(['FULL_ACCESS', 'CONFIG_RW'])" :to="{name:'eventmanagerEdit', params: {id: event.id}}" class="mr-0">
              <v-icon small class="mr-2">fadl fa fa-pencil</v-icon>
              Edit
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon title="More options" >fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list>
              <v-list-item v-if="event && event.deeplink_url" @click="showQrCode = true">
                <v-list-item-icon><v-icon>fal fa-qrcode</v-icon></v-list-item-icon>
                <v-list-item-title>Open in App</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="event && false" :to="{name: 'eventGettingStartedChina', params: {id: this.event.id}, query: {code: joinInfo && joinInfo.join_code}}">
                <v-list-item-icon><v-icon>fal fa-mobile</v-icon></v-list-item-icon>
                <v-list-item-title>China Instructions</v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item v-if="event.published && eventUtil.hasRole('FULL_ACCESS')" :to="{name:'eventmanagerLinkNext', params: {id: event.id}}">
                <v-list-item-icon><v-icon>fal fa-link</v-icon></v-list-item-icon>
                <v-list-item-title>Link follow-up event</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="eventUtil.hasRole(['FULL_ACCESS', 'CONFIG_RW'])" :to="{name:'eventmanagerLinkOrg', params: {id: event.id}}">
                <v-list-item-icon><v-icon>fal fa-building</v-icon></v-list-item-icon>
                <v-list-item-title>Link with organization</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="eventUtil.hasRole(['FULL_ACCESS', 'CONFIG_RW'])" :to="{name:'eventmanagerClone', params: {id: event.id}}">
                <v-list-item-icon><v-icon>fal fa-clone</v-icon></v-list-item-icon>
                <v-list-item-title>Clone</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="eventUtil.hasRole(['FULL_ACCESS', 'CONFIG_RW']) && eventUtil.hasMeta('cfg.template.id') && !event.published" @click="disconnectTemplate">
                <v-list-item-icon><v-icon>fal fa-unlink</v-icon></v-list-item-icon>
                <v-list-item-title>Disconnect template</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="eventUtil.hasRole(['FULL_ACCESS', 'CONFIG_RW']) && !eventUtil.hasMeta('cfg.template.id') && !event.published && event.races && event.races.length > 0" :to="{name:'eventmanagerStart', query: { appendEventId: event.id}}">
                <v-list-item-icon><v-icon>fal fa-link</v-icon></v-list-item-icon>
                <v-list-item-title>Append from template</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item v-if="eventUtil.hasRole('FULL_ACCESS')" @click="duplicateAsSimulation">
                <v-list-item-icon><v-icon>fal fa-play</v-icon></v-list-item-icon>
                <v-list-item-title>Create copy in simulator</v-list-item-title>
              </v-list-item> -->
              <v-divider/>
              <v-list-item v-if="eventUtil.hasRole(['FULL_ACCESS', 'CONFIG_RW'])" :to="{name:'eventmanagerArchive', params: {id: event.id}}">
                <v-list-item-icon><v-icon>fal fa-archive</v-icon></v-list-item-icon>
                <v-list-item-title>Archive</v-list-item-title>
              </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-toolbar>
        <v-container class="info-container grey lighten-5 py-0">
          <v-row>
            <v-col cols="12" sm="6">
              <div class="label">ID</div>
              <div class="value"><pre>{{event.id}}</pre></div>
              
              <div class="label">Name</div>
              <div class="value">{{event.name}}</div>

              <div v-if="event.published">
              <div v-if="tenant.id == 'cofi'">
                <div class="label">Getting Started Link</div>
                <div class="value">
                  <code>{{eventUtil.gettingStartedUrl()}}</code>
                  <v-btn text color="accent" small @click="$helpers.copyToClipboard(eventUtil.gettingStartedUrl(), $toast)"><v-icon small class="mr-2">fa-copy</v-icon> Copy</v-btn>
                  <v-dialog v-if="eventUtil.gettingStartedUrl() && event.verification_method === 'REG_DATA'" max-width="400px">
                    <template v-slot:activator="{ on }">
                      <v-btn text small color="accent" v-on="on">
                        <v-icon small class="mr-2">fadl fa fa-qrcode</v-icon> View QR
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Getting Started QR</v-card-title>
                      <v-card-text>Scan this QR code to open the Getting Started page.</v-card-text>
                      <QrCode :data="eventUtil.gettingStartedUrl()" />
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              <div v-if="event.reg_config && event.reg_config.enabled">
                <div class="label">Registration Link</div>
                <div class="value">
                  <code>{{eventUtil.registrationUrl()}}</code>
                  <v-btn text color="accent" small @click="$helpers.copyToClipboard(eventUtil.registrationUrl(), $toast)"><v-icon small class="mr-2">fa-copy</v-icon> Copy</v-btn>
                  <v-dialog max-width="400px">
                    <template v-slot:activator="{ on }">
                      <v-btn text small color="accent" v-on="on">
                        <v-icon small class="mr-2">fadl fa fa-qrcode</v-icon> View QR
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Getting Started QR</v-card-title>
                      <v-card-text>Scan this QR code to open the Getting Started page.</v-card-text>
                      <QrCode :data="eventUtil.registrationUrl()" />
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              <div class="label">Invite Link <a v-if="tenant.isDefault" href="https://corporatefitness.helpscoutdocs.com/category/14-onboarding" target="_blank">(how to use)</a></div>
                <div v-if="event.verification_method === 'ALLOWLIST'" class="value">
                  <v-btn text color="accent" small @click="$helpers.copyToClipboard(joinUrl, $toast)"><v-icon small class="mr-2">fa-copy</v-icon> Copy</v-btn>
                  <v-dialog v-if="tenant.id == 'cofi'" max-width="400px">
                    <template v-slot:activator="{ on }">
                      <v-btn text small color="accent" v-on="on">
                        <v-icon small class="mr-2">fadl fa fa-qrcode</v-icon> View QR code
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Join Event QR</v-card-title>
                      <v-card-text>Scan this QR code with your phone to quickly join this event using our app. This will download the iOS or Android depending on the phone.</v-card-text>
                      <v-card-text>Share this code with the other participants so they can join as well.</v-card-text>
                      <QrCode :data="joinUrl" />
                    </v-card>
                  </v-dialog>
                  <v-btn text small color="accent" @click="emailInviteToMe">
                    <v-icon small class="mr-2">fadl fa fa-envelope</v-icon> Email me
                  </v-btn>
                </div>
                <div v-else-if="event.verification_method === 'REG_DATA'" class="value">
                  <div v-if="event.verification_method === 'REG_DATA'" class="">
                    <router-link :to="{name:'eventmanagerRegistrations', params: {id: this.event.id}}">Manage Registrations or Import an Excel file</router-link> to automatically send personal invite emails.
                  </div>
                </div>
                <div v-else-if="tenant.id == 'cofi'" class="value">
                  <v-btn text color="accent" small @click="$helpers.copyToClipboard(joinUrl, $toast)"><v-icon small class="mr-2">fa-copy</v-icon> Copy</v-btn>
                </div>
                <div v-else-if="event.deeplink_url" class="value">
                  <v-btn text color="accent" small @click="showQrCode = true"><v-icon small class="mr-2">fa-qrcode</v-icon> Show Code</v-btn>
                  <v-btn text color="accent" small @click="$helpers.copyToClipboard(joinUrl, $toast)"><v-icon small class="mr-2">fa-copy</v-icon> Copy</v-btn>
                </div>
                <div v-else class="value">
                  <div>{{joinUrl}}</div>
                </div>
              </div>
              <div v-else> <!-- not published yet -->
                <div class="label">Invite method</div>
                <div class="value">
                  <span v-if="event.verification_method === 'REG_DATA'">Personal Invites</span>
                  <span v-if="event.verification_method === 'ALLOWLIST'">Reusable QR/link</span>
                  <v-btn text color="primary" small :to="{name: 'eventmanagerConfigOnboarding', params: {id: event.id} }">Change</v-btn>
                  <div v-if="event.verification_method === 'ALLOWLIST'" class="text-muted">QR code is shown when event is published</div>

                </div>              
              </div>



              <div class="label">Opens (included)</div>
              <div class="value">
                {{event.from | localDate("L", /* inOriginalTimeZone */ true)}}
                <v-chip  v-if="$helpers.isActiveEvent(event)" outlined color="accent darken-0" class="white--text">Active now!</v-chip>
                <v-chip  v-else-if="$helpers.isFutureEvent(event)" outlined color="accent darken-0" class="white--text">Opens in {{$helpers.getStartDurationForEvent(event) | duration(true)}}</v-chip>
              </div>

              <div class="label">Closes (included)</div>
              <div class="value">
                {{event.till | localDate("L", /* inOriginalTimeZone */ true)}}
                <v-chip v-if="$helpers.isActiveEvent(event)" outlined color="accent darken-0" class="white--text">Closes in {{$helpers.getEndDurationForEvent(event) | duration(true)}}</v-chip>
                <v-chip v-else-if="$helpers.isFutureEvent(event)" outlined color="accent darken-0" class="white--text">Duration {{Math.round($helpers.getDurationForEvent(event).length('days'))}} days</v-chip>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              
              <div class="label">Mode</div>
              <div class="value">
                <ActivityModeChip :mode="event.activity_mode" :conversionMode="event.conversion_mode" />
                <div v-if="event.auto_step_algo" class="text-hint mt-2">
                  Custom step conversion: <router-link :to="{name:'resourcesCalculateSteps', query: {algo: event.auto_step_algo}}">{{event.auto_step_algo}}</router-link>
                </div>
                <div v-if="event.conversions && event.conversions.length" class="text-hint mt-2">
                  Activity conversions are configured: <v-btn text small color="accent" :to="{name:'eventmanagerActivityConversions', params: {id: event.id}}">Manage</v-btn>
                </div>
                <div v-if="event.custom_activity_types && event.custom_activity_types.length" class="text-hint mt-2">
                  Custom activity types are configured: <v-btn text small color="accent" :to="{name:'eventmanagerConfigActivityTypes', params: {id: event.id}}">Manage</v-btn>
                </div>
              </div>
              
              <div v-if="eventUtil.hasMeta('cfg.template.id')">
              <div class="label">Template</div>
              <div class="value">
                <v-chip color="accent" outlined>{{ eventUtil.getMetaStringValue('cfg.template.name') }}</v-chip>
                <v-btn v-if="!eventUtil.hasMeta('cfg.template.persisted_at')" text small color="accent" :to="{name:'eventmanagerConfigTemplate', params: {id: event.id }}">Finalize</v-btn>
              </div>
              </div>
              <div v-else-if="!event.published && (!event.races || !event.races.length)">
              <div class="label">Template</div>
              <div class="value">
                <v-btn text small color="accent" :to="{name:'eventmanagerStart', query: {eventId: event.id}}">Link</v-btn>
              </div>
              </div>


              <div class="label">Manual Entry Allowed</div>
              <div class="value">
                <v-icon color="accent">fal {{ event.allow_manual_entry ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon>
                <!-- <v-chip v-if="event.auto_manual_entry_step_conversion" outlined color="accent darken-0" class="white--text">Auto Step Conversion Enabled</v-chip> -->
              </div>

              <div class="label">Validate pace/speed</div>
              <div class="value"><v-icon color="accent">fal {{ event.validate_activities ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon></div>

              <div v-if="event.published" class="label">Verification Required to Join</div>
              <div v-if="event.published" class="value">
                <span v-if="event.verification_method === 'ALLOWLIST' && joinInfo && joinInfo.join_code">With the code <v-chip outlined>{{joinInfo.join_code}}</v-chip></span>
                <span v-else-if="event.verification_method === 'ALLOWLIST'">With Anonymous Codes</span>
                <span v-else-if="event.verification_method === 'REG_DATA'">From Registration Data</span>
                <span v-else>Open to Anyone</span>
              </div>

              <div v-if="event.datacenter_id" class="label">Datacenter</div>
              <div v-if="event.datacenter_id" class="value">
                {{event.datacenter_id | uppercase}}
              </div>

              <div v-if="event.published" class="label">Visibility</div>
              <div v-if="event.published" class="value">{{ event.public ? 'Public' : 'Private (with link only)' }}</div>

              <div v-if="event.default_lang" class="label">Languages</div>
              <div v-if="event.default_lang" class="value">
                <v-chip color="primary">{{event.default_lang}}</v-chip>
                <v-chip v-for="item in event.available_langs" color="primary" outlined class="ms-1" :key="item">{{item}}</v-chip>
                <v-btn text color="accent" small :to="{name:'eventmanagerTranslations', params: {id: event.id}}">Manage</v-btn>
              </div>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-alert v-if="event.locked || event.auto_locked" type="info" tile outlined border="left">
                <v-row dense>
                  <v-col class="grow">Leaderboards are locked for this event. No changes to results are allowed.</v-col>
                  <v-col class="shrink">
                    <v-btn class="shrink" text color="blue" :to="{name:'eventmanagerLock', params: {id: event.id}}">Manage</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
              <v-alert v-if="isTrial" type="info" tile outlined border="left">
                <p>This is a free trial event. </p>
                <v-btn outlined color="info" @click="assistance" :loading="$store.getters.isLoading">Get Support</v-btn>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <v-alert v-if="event.user_created" tile type="info" class="mb-0">
          <p>This is a user-created challenge. Some options are disabled.</p>
        </v-alert>
        <!-- <v-alert v-if="!event.cleaned_date && tenant.id === 'cofi' && event.show_onboarding_setup" tile type="info" color="info lighten-1" class="mb-0">
          <div class="message-plus-button">
            <p>Configure onboarding and event rules.</p>
            <v-btn :to="{name: 'eventmanagerConfigOnboarding', params: {id: event.id} }">Configure Now</v-btn>
          </div>
        </v-alert> -->
        <v-alert v-if="!event.cleaned_date && !event.published && event.validation && event.validation.meta.status != 'ERROR'" tile type="info" class="mb-0">
          <div class="message-plus-button">
            <p>This event is not yet published. An event must be published before it can be used or viewed.</p>
            <v-btn @click="publish" :loading="$store.getters.isLoading">Publish Now</v-btn>
          </div>
        </v-alert>
        <v-alert v-if="!event.cleaned_date && !event.published && event.validation && event.validation.meta.status != 'ERROR'" tile type="info" color="info darken-2" icon="fa-question-circle" class="mb-0">
          <div class="message-plus-button">
            <p>Need a little help to get your event ready to go?</p>
            <v-btn outlined @click="assistance" :loading="$store.getters.isLoading">Get Assistance</v-btn>
          </div>
        </v-alert>

        <v-alert v-if="eventUtil.hasMeta('requested.count') || eventUtil.hasMeta('requested.support')" tile type="info" class="mb-0">
          <div class="message-plus-button">
            <p>Complete your payment to continue.</p>
            <v-btn :to="{name: 'eventmanagerLicense', params: {id: event.id} }">Pay Now</v-btn>
          </div>
        </v-alert>
        <v-alert v-else-if="eventUtil.hasMeta('cfg.template.id') && !eventUtil.hasMeta('cfg.template.persisted_at')" tile type="info" class="mb-0">
          <div class="message-plus-button">
            <p>Please finalize the setup of the {{eventUtil.getMetaStringValue('cfg.template.name')}} template configuration.</p>
            <v-btn :to="{name:'eventmanagerConfigTemplate', params: {id: event.id }}">Finalize</v-btn>
          </div>
        </v-alert>
        <v-alert v-else-if="(!event.races || !event.races.length)" tile type="info" class="mb-0">
          <div class="message-plus-button">
            <p>Please add a leaderboard.</p>
            <v-btn @click="$refs.addRaceDialog.open()">Add Leaderboard</v-btn>
          </div>
        </v-alert>
        <v-alert v-else-if="event.validation && event.validation.meta.status == 'ERROR'" tile type="error" class="mb-0">
          <p>There are one or more validation issues. Please resolve these before going live:</p>
          <ul class="font-weight-bold"><li v-for="(item,idx) in event.validation.data" :key="idx">{{item.msg}}</li></ul>
        </v-alert>
        <v-alert v-else-if="event.validation && event.validation.meta.status == 'INFO'" tile type="info" class="mb-0">
          <p>There are one or more settings that might need your attention. Please have a look before going live:</p>
          <ul class="font-weight-bold"><li v-for="(item,idx) in event.validation.data" :key="idx">{{item.msg}}</li></ul>
        </v-alert>


        <QuickSurvey 
          v-if="tenant.id=='cofi' && event.published && $helpers.isPastEvent(event)" 
          name="cofi-eventmanager-event-completed-experience"
          question="Did you like your experience with CorporateFitness.app?" 
          :items="[{value:'yes', text:'Yes, loved it!'}, {value:'neutral', text:'Was okay'}, {value:'no', text:'Not so much'}]"
          class="ma-4"
          >
          <template v-slot:voted="{ item }">
            <div v-if="item && item.value == 'yes'" class="mb-0">
              <p>Thanks for your feedback! Would you mind to give us a review? That would be very helpful!</p>
              <p>
                <v-btn large color="primary" href="https://reviews.capterra.com/new/357821/115888ee-151b-4e48-9920-774dd1ec9539?lang=en" target="_blank">Give Review</v-btn>
              </p>
            </div>
          </template>
        </QuickSurvey>
        
        <div v-if="event.next_event_id">
          <v-divider />
          <v-list-item :to="{name: 'eventmanagerView', params: {id: event.next_event_id} }">
            <v-list-item-title>
              A <strong>follow-up</strong> event has been configured.
            </v-list-item-title>
            <v-list-item-action>
              <v-btn text color="primary" :to="{name: 'eventmanagerView', params: {id: event.next_event_id} }">View</v-btn>            
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
        <div v-if="tenant.id==='cofi' && org">
          <v-divider />
          <v-list-item :to="{name: 'orgmanagerView', params: {id: org.id}}">
            <v-list-item-icon v-if="org.logo">
              <v-img :src="org.logo" width="50" />
            </v-list-item-icon>
            <v-list-item-title>
              This event is part of the <strong>{{org.name}}</strong> organization
            </v-list-item-title>
            <v-list-item-action>
              <v-btn text color="primary" :to="{name: 'orgmanagerView', params: {id: org.id}}">View</v-btn>            
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
        <EventStatusCard :event="event" :joinUrl="joinUrl" @publish="publish"/>
        <div v-if="tenant.id==='cofi'">
          <v-card-title class="subtitle">
            <span class="mr-2">License Plan</span>
            <span class="mr-2 font-weight-bold">{{ event.license | license }}</span>
            <v-btn v-if="event.license == 'FREE'" text color="primary" :to="{name: 'eventmanagerLicense', params: {id: event.id}}">Upgrade</v-btn>            
          </v-card-title>
          <v-card-text v-if="licenseInfo">
            <p v-if="licenseInfo.valid">
              <v-icon color="green">fa-check-circle</v-icon>
              Valid license.
            </p>
            <v-alert v-else type="error" prominent>
              <p>All credits used. No new users can join this challenge until new credits are purchased.</p>
              <p>
                <v-btn v-if="event.org && event.org_license" :to="{name: 'orgmanagerSubscription', params: {id: event.org.id}}">Purchase Now</v-btn>                            
                <v-btn v-else :to="{name: 'eventmanagerLicense', params: {id: event.id}}">Purchase Now</v-btn>                            
              </p>
            </v-alert>
            <div class="d-flex flex-row">
              <v-progress-linear striped class="ma--4" height="25" color="green" :value="Math.round(licenseInfo.current_capacity / licenseInfo.max_capacity * 100)" />
              <div class="shrink mt-0 ml-4" style="white-space:nowrap;">{{ licenseInfo.max_capacity }} users</div>
            </div>
            <span class="text-muted">{{ licenseInfo.current_capacity }} users out of {{ licenseInfo.max_capacity }} users have joined. Actual number of joined users might have a slight processing delay.</span>
          </v-card-text>
          <v-card-text v-else>
            Loading...
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="event.org && event.org_license" text :to="{name: 'orgmanagerSubscription', params: {id: event.org.id}}">Purchase additional seats</v-btn>                            
            <v-btn v-else text :to="{name: 'eventmanagerLicense', params: {id: event.id}}">Purchase additional seats</v-btn> 

            <v-btn v-if="event.org && event.org_license" text :to="{name: 'orgmanagerSubscription', params: {id: event.org.id}}">Change License</v-btn>                            
            <v-btn v-else text :to="{name: 'eventmanagerLicense', params: {id: event.id}}">Change License</v-btn>            
          </v-card-actions>
        </div>


        <v-data-table
          :headers="headers"
          :items="races"
          :items-per-page="50"
          hide-default-footer
          @click:row="item => eventUtil.newLayout ? $router.push({name: 'eventmanagerRace', params: { id:event.id, raceId: item.id }}) : null"
        >
          <template v-slot:top>
            <v-toolbar flat color="white" class="mx-4">
              <v-toolbar-title class="ml-0 subtitle">Leaderboards</v-toolbar-title>
              <v-spacer/>
              <v-btn v-if="eventUtil.hasRole('FULL_ACCESS') && eventUtil.showAdvancedEventOptions()" text color="primary" class="mr-0" :to="{name: 'eventmanagerRaces', params: { id:event.id }}">
                Re-order
              </v-btn>
              <v-btn v-if="eventUtil.hasRole('FULL_ACCESS') && eventUtil.showAdvancedEventOptions()" outlined color="primary" class="mr-0" @click="$refs.addRaceDialog.open()">
                <v-icon class="mr-2">fa-plus</v-icon> Add Leaderboard
              </v-btn>
              <v-menu v-if="!eventUtil.newLayout" offset-y max-height="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" color="primary" class="px-2" style="min-width:0;">
                    <v-icon title="More options" >fa-caret-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                  <v-list-item v-if="tenant.id !== 'cofi'" @click="$refs.raceDialog.addInPersonRace()">
                    <v-list-item-title>Add In-Person Race</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$refs.raceDialog.addStepChallenge()">
                    <v-list-item-title>Add Step Challenge</v-list-item-title>
                  </v-list-item>
                  <v-list-item :disabled="!eventUtil.isProPlan()" @click="$refs.trainingPlanDialog.open()">
                    <v-list-item-title>Add Training Plan</v-list-item-title>
                    <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
                  </v-list-item>
                  <v-list-item :disabled="!eventUtil.isProPlan()" @click="$refs.streakDialog.open()">
                    <v-list-item-title>Add Daily Streak</v-list-item-title>
                    <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
                  </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>

            </v-toolbar>
          </template>
          <template v-slot:item.activity_types="{ item }">
            <span v-if="!item.activity_types || item.activity_types.length==0">All</span>
            <span v-else v-for="(type, idx) in item.activity_types" :key="idx">
              <v-icon :title="$helpers.getActivityTypeLabel(type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
              <span v-if="idx != item.activity_types.length-1" class="mx-1">{{ $t('shared.or') }}</span>
            </span>
          </template>
          <template v-slot:item.scoring="{ item }">
            <v-icon :title="item.scoring">{{$helpers.getScoringIcon(item.scoring)}}</v-icon>
          </template>
          <template v-slot:item.dist="{ item }">
            {{ $helpers.getGoalDistanceForDisplay($options, item, item.dist, event) }}
            <div v-if="item.min_dist" class="text-muted">
              Activities: {{ $helpers.getDistanceForDisplay($options, item, item.min_dist, event.unit) }} - {{ $helpers.getDistanceForDisplay($options, item, item.max_dist, event.unit) }}
            </div>
            <div v-if="item.min_duration_s" class="text-muted">
              Activities &gt; {{ item.min_duration_s | duration }}
            </div>
            <div v-if="item.from" class="text-muted">
              {{item.from | localDate("L", /* inOriginalTimeZone */ true)}} - {{item.till | localDate("L", /* inOriginalTimeZone */ true)}}
            </div>
          </template>
          <template v-slot:item.name="{ item }">
            <strong>{{ item.name }}</strong>
            <div v-if="item.team" class="font-weight-bold">({{$helpers.displayText('team_types', item.team, 'UNKNOWN') }})</div>
            <div v-if="item.type === 'HYBRID'" class="font-weight-bold">(hybrid)</div>
            <div v-if="item.type === 'EXTERNAL'" class="font-weight-bold">(in-person)</div>
            <div v-if="item.import_id">Join Key: <code>{{item.import_id}}</code></div>
          </template>
          <template v-slot:item.filter="{ item }">
            <div v-if="item.min_dist" class="text-muted">
              Activities: {{ $helpers.getDistanceForDisplay($options, item, item.min_dist, event.unit) }} - {{ $helpers.getDistanceForDisplay($options, item, item.max_dist, event.unit) }}
            </div>
            <div v-if="item.from" class="text-muted">
              {{item.from | localDate("L")}} - {{item.till | localDate("L")}}
            </div>
          </template>
          <template v-slot:item.visibility="{ item }">
            {{ $helpers.displayText(siteData.race_visibility, item.visibility, 'Visible') }}
            <span v-if="item.participants_only" class="text-muted">(only to participants)</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon v-if="!eventUtil.newLayout" small color="primary" class="mr-4" @click="$refs.raceDialog.editRace(item)">
              fa-pencil-alt
            </v-icon>
            <v-btn v-if="!eventUtil.newLayout && item.type !== 'EXTERNAL'" small color="primary" text class="" :to="{name: 'eventmanagerRaceBadges', params: { id:event.id, raceId: item.id }}">
              <v-icon small class="mr-2">fal fa fa-shield</v-icon> Badges
            </v-btn>
            <v-btn v-if="item.type === 'EXTERNAL' || item.type === 'HYBRID'" small color="primary" text class="" @click.stop.prevent="uploadResults(item)">
              <v-icon small class="mr-2">fal fa fa-upload</v-icon> Upload
            </v-btn>
            <v-btn v-if="eventUtil.newLayout" small color="primary" text class="" :to="{name: 'eventmanagerRace', params: { id:event.id, raceId: item.id }}">
              <v-icon small class="mr-2">fal fa fa-chevron-right</v-icon> View
            </v-btn>
          </template>
          <template v-slot:item.more="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>fa-ellipsis-v</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-if="item.team == 'RELAY_TIME_SLOTS' || item.team == 'RELAY_DISTANCE_SLOTS'" :disabled="!eventUtil.isProPlan()" :to="{name: 'eventmanagerRaceRelaySlots', params: { id:event.id, raceId: item.id }}">
                  <v-list-item-icon><v-icon>fa-users-class</v-icon></v-list-item-icon>
                  <v-list-item-title>Relay Slots</v-list-item-title>
                  <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
                </v-list-item>
                <v-list-item :to="{name: 'eventmanagerRaceResultColumns', params: { id:event.id, raceId: item.id }}">
                  <v-list-item-icon><v-icon>fa-line-columns</v-icon></v-list-item-icon>
                  <v-list-item-title>Customize Leaderboard</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.type !== 'EXTERNAL'" :to="{name: 'eventmanagerRaceResults', params: { id:event.id, raceId: item.id }}">
                  <v-list-item-icon><v-icon>fa-poll-people</v-icon></v-list-item-icon>
                  <v-list-item-title>Manage results</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.type !== 'EXTERNAL'" :disabled="!eventUtil.isProPlan()" :to="{name: 'eventmanagerRaceGeofence', params: { id:event.id, raceId: item.id }}">
                  <v-list-item-icon><v-icon>fa-draw-polygon</v-icon></v-list-item-icon>
                  <v-list-item-title>Geo-fence</v-list-item-title>
                  <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
                </v-list-item>
                <v-list-item v-if="item.type !== 'EXTERNAL' && (item.scoring == 'DISTANCE'||item.scoring == 'ELEVATION'||item.scoring == 'TRACK'||item.scoring == 'CUSTOM')" @click="uploadGpxTrack(item)">
                  <v-list-item-icon><v-icon>fa-route</v-icon></v-list-item-icon>
                  <v-list-item-title>Upload GPX track</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.type === 'EXTERNAL' || item.type === 'HYBRID'" :disabled="!eventUtil.isProPlan()" @click="uploadResults(item)">
                  <v-list-item-icon><v-icon>fa-upload</v-icon></v-list-item-icon>
                  <v-list-item-title>Upload Results</v-list-item-title>
                  <v-list-item-action v-if="!eventUtil.isProPlan()"><v-chip outlined class="pro-only">PRO</v-chip></v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="deleteRace(item)" >
                  <v-list-item-icon><v-icon>fa-trash</v-icon></v-list-item-icon>
                  <v-list-item-title>Delete Leaderboard</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>            
          </template>
          <template v-slot:no-data>
            <i>No leaderboards created, add at least one leaderboard to this event.</i>
          </template>
        </v-data-table>
      </div>   
    </v-card>
    <SupportButton/>
    <AddRaceDialog ref="addRaceDialog" :event="event" @saved="newRaceSaved"/>
    <RaceDialog ref="raceDialog" :event="event" @save="saveEvent"/>
    <v-dialog v-if="event && event.deeplink_url" v-model="showQrCode" max-width="400px">
      <v-card>
        <v-card-title>View in app</v-card-title>
        <v-card-text>
          <v-text-field readonly v-model="event.deeplink_url"/>
          <v-btn outlined block class="shrink" @click="$helpers.copyToClipboard(event.deeplink_url, $toast);showQrCode=false;">Copy URL to clipboard</v-btn>
        </v-card-text>
        <v-card-text>Scan this QR code with your phone to quickly open this event in our app. This will download the iOS or Android depending on the phone.</v-card-text>
        <QrCode :data="event.deeplink_url" />
      </v-card>
    </v-dialog>      
    <v-dialog v-if="event" v-model="gpxDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload virtual course</span>
        </v-card-title>
        <v-card-text>
          <p>Upload a virtual course track. You can upload any gpx file. Please make sure to select a gpx file containing a track matching the leaderboard distance.</p>
          <v-alert v-if="activeRace && activeRace.route" type="info">Note: uploading a track will overwrite the current track.</v-alert>
          
          <ActivityUploadForm :event-id="event.id" :race-id="activeRace ? activeRace.id : null" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeGpxDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <v-dialog v-if="event && activeRace" v-model="resultsDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload race results for {{activeRace.name}}</span>
        </v-card-title>
        <v-card-text>
          <p>Upload an Excel or csv file with race results.</p>
          
          <ExcelUploadForm :event="event" :event-id="event.id" :race-id="activeRace.id" @uploaded="resultsDialog=false;" type="RACE_RESULTS"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resultsDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <v-dialog v-if="event && !event.published" v-model="showPreviewDialog" fullscreen>
      <v-card color="white">
        <v-toolbar dark tile color="grey darken-4">
          <v-toolbar-title class="mx-2">Preview {{event.name}}</v-toolbar-title>
          <v-spacer/>
          <v-btn dark text class="ml-2" @click="showPreviewDialog = false">
            <v-icon class="mr-2">fa-times-circle</v-icon>
            Close
          </v-btn>
        </v-toolbar>
        <v-progress-circular v-if="!previewFrameLoaded" color="primary" size="100" class="align-center" indeterminate />
        <iframe v-show="previewFrameLoaded" style="border:0;" class="full-dialog" loading="lazy" :src="$router.resolve({name: 'event', params: {id: event.id}, query: {draft: true, view: 'app'}}).href" @load="previewFrameLoaded=true"></iframe>

      </v-card>
    </v-dialog>  
    <v-dialog v-if="state == 'template_configured'" v-model="state" max-width="500">
      <v-card>
        <v-card-title>Event is configured.</v-card-title>
        <v-alert prominent type="success" tile>
          All good, your event has been configured based on your preferences.
        </v-alert>        
        <v-card-text>
          You can now finalize the setup and publish your event when you're ready to start inviting the participants.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="state = null;">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <SelectTrainingPlanDialog ref="trainingPlanDialog" :event="event" @addPlan="addTrainingPlan" />
    <GenerateStreakDialog ref="streakDialog" :event="event" @submit="addStreak" />
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import QrCode from '@/components/generic/QrCode.vue'
import SupportButton from '@/components/SupportButton.vue'
import Header from './_Header.vue'
import AddRaceDialog from './_AddRaceDialog.vue'
import RaceDialog from './_RaceDialog.vue'
import EventStatusCard from './_EventStatusCard.vue'
import SelectTrainingPlanDialog from './_SelectTrainingPlanDialog.vue'
import GenerateStreakDialog from './_GenerateStreakDialog.vue'
import ActivityUploadForm from "@/components/ActivityUploadForm";
import ActivityModeChip from "@/components/ActivityModeChip";
import ExcelUploadForm from "@/components/ExcelUploadForm";
import QuickSurvey from "@/components/QuickSurvey";
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    SupportButton,
    Header,
    AddRaceDialog,
    RaceDialog,
    EventStatusCard,
    ActivityUploadForm,
    ExcelUploadForm,
    SelectTrainingPlanDialog,
    ActivityModeChip,
    QuickSurvey,
    GenerateStreakDialog,
    QrCode,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      state: null,
      error: null,
      breadcrumbItems: [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'View event', disabled: true },
      ],
      scoringTypeItems: siteData.scoring_methods,
      event: null,
      activeRace: null,
      gpxDialog: false,
      showQrCode: false,
      resultsDialog: false,
      showPreviewDialog: false,
      previewFrameLoaded: false,
      races: [],
      licenseInfo: null,
      joinInfo: null,
      stats: null,
      showReviewBar: false,
      eventManagerService: eventManagerService,
      headers: [
        { text: 'Activities', align: 'start', sortable: false, value: 'activity_types',},
        { text: 'Scoring', align: 'start', sortable: false, value: 'scoring',},
        { text: 'Name', align: 'start', sortable: false, value: 'name',},
        { text: 'Goal', value: 'dist', sortable: false },
        { text: 'Filter', value: 'filter', sortable: false },
        { text: 'Visibility', value: 'visibility', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Options', value: 'more', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getProfile();

    this.state = this.$route.query.state;

    if (this.eventUtil.newLayout) {
      // remove Filters column
      this.headers.splice(4, 1);
      // remove Options column
      this.headers.splice(this.headers.length - 1, 1);
    }
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
    EventBus.$on('eventmanager-refresh', async state => {
      console.log('refresh requested', state);
      this.event = state.event; // force refresh down the tree
      this.races = this.event.races || [];
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.races = this.event.races || [];
      this.org = this.event.org;

      this.licenseInfo = (await eventManagerService.getLicenseStatus(id)).data;
      this.joinInfo = { join_url: this.event.join_url };
      if (this.event.verification_method === 'ALLOWLIST') {
        this.joinInfo = (await eventManagerService.getJoinInfo(id)).data;
      }
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async addTrainingPlan(plan) {
      console.log('Adding new plan', plan);
      var request = {
        training_plan_id: plan.id,
      }
      var response = (await eventManagerService.addTrainingPlan(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added training plan.');
      if (response.status === 'OK') {
        this.getProfile();
      }
    },

    async addStreak(request) {
      console.log('Adding new streak', request);
      var response = (await eventManagerService.addStreakChallenge(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added challenge.');
      if (response.status === 'OK') {
        this.getProfile();
      }
    },

    async deleteRace (item) {
      const index = this.races.indexOf(item);
      confirm('Are you sure you want to DELETE THIS RACE AND ALL RESULTS?') && this.races.splice(index, 1) && await this.saveEvent();
    },

    uploadGpxTrack(item) {
      this.activeRace = item;
      this.gpxDialog = true
    },
    closeGpxDialog() {
      this.gpxDialog = false;
    },

    uploadResults(item) {
      this.activeRace = item;
      this.resultsDialog = true
    },

    async publish() {
      //if (confirm('Are you sure you want to publish this event? Some settings might not be available .')){
        await this.eventUtil.refresh();
        this.event.published = true;
        await this.eventUtil.requestSave();
        this.$analytics.event('publish_event');

        if (this.event.license === 'FREE' || !this.event.max_participants) {
          this.$router.push({name: 'eventmanagerLicense', params: {id: this.event.id}});
        }
      //}
    },

    async assistance() {
      //if (confirm('Are you sure you want to publish this event? Some settings might not be available .')){
        //this.event.published = true;
        //this.saveEvent();
      //}
      var profile = (await profileService.get()).data;
      console.log('this.user', this.user, profile);
      const data = { 
        name: profile.name,
        email: this.event.email,
        comments: `Event: ${this.event.name}
Id: ${this.event.id}
Link: ${tenant.homeUrl}eventmanager/view/${this.event.id}
Remarks: ${this.event.remarks || ''}
Question: <please add your question here>`,
      };
      this.$router.push({ name: 'contact', query: data });
    },

    async newRaceSaved(response) {
      console.log('newRaceSaved', response, response.id);
      if (response.id) {
        this.$router.push({name: 'eventmanagerRace', params: { id: this.event.id, raceId: response.id }});
      }
    },
    async saveEvent() {
      this.error = null;
      try {
        //this.races = this.event.races;
        //await this.eventUtil.save();
        var response = await eventManagerService.put(this.event);
        await this.eventUtil.refresh();
        this.$toast.success('Event is saved successfully!');
      }
      catch (ex) {
        this.error = ex.response.data.msg;
      }
    },
    async duplicateAsSimulation() {
      if (confirm('Are you sure you want to create a copy of the current setup and load this in the simluator?')) {
        const response = (await eventManagerService.duplicateAsSimulation(this.event.id)).data;
        if (response.id) {
          this.$router.push({ name: 'eventmanagerSimulator', query: { id: response.id.replace('itx-', ''), autorun: true } });
          //await this.getProfile();
        }
      }
    },
    async disconnectTemplate() {
      if (confirm('Are you sure you want to disconnect the template? This will clear the current event configuration.')) {
        const response = (await eventManagerService.disconnectEventTemplate(this.event.id)).data;
        this.$helpers.toastResponse(this, response, 'The template has been disconnected.');
        this.eventUtil.refresh();
      }
    },
    async emailInviteToMe() {
      const response = await eventManagerService.sendInviteEventEmail(this.event.id);
      this.$helpers.toastResponse(this, response.data, 'The invite email is on it\'s way! Please check your inbox in a few minutes from now.');
    },


  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    distanceLabel() {
      if (this.editedItem == null || this.editedItem.scoring == null || this.editedItem.scoring == "RESULT") {
        return `Race distance in (in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "DISTANCE"){
        return `Total distance goal (in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "ELEVATION"){
        return `Total elevation goal (in ${this.unitTypeDiplay})`;
      }
      return null;
    },
    isTrial() {
      return this.event && this.event.id.startsWith('trial-');
    },
    joinUrl() {
      if (this.event == null) {
        return null;
      }
      return this.joinInfo ? this.joinInfo.join_url : this.event.join_url;
    },
    unitTypeDiplay() {
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id);
    this.eventUtil.refresh();
    next();
  },
};
</script>
<style lang="scss">
  .full-dialog { 
      width:100vw; 
      height: 100vh;
      /*.toastui-editor-defaultUI { position: absolute; top:0; bottom: 0; right: 0; left: 0;}*/
    }
</style>

